<script setup lang="ts">
const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
})

const route = useRoute()

const { to, page, itemsPerPage } = usePagination()

const pageCount = Math.ceil(props.total / itemsPerPage) - 1

const isFirstPage = page.value === 1 || !page.value
const isLastPage = page.value === pageCount + 1

// if query not a number
if (route.query.strona && isNaN(route.query.strona))
  navigateTo(route.path, { redirectCode: 301 })

// if query has negative value
if (route.query.strona < 0)
  navigateTo(route.path, { redirectCode: 301 })

// if only one page
if (route.query.strona == 0 || route.query.strona == 1) {
  navigateTo(route.path, { redirectCode: 301 })
}

// if query has value > max
if (page.value > pageCount + 1)
  navigateTo(route.path, { redirectCode: 301 })
</script>

<template>
  <nav class="bg-gray-800 mt-4">
    <UContainer>
      <div class="flex items-center pb-4 px-4">
        <div class="flex flex-1">
          <template v-if="isFirstPage">
            <button
              disabled
              class="text-sm text-gray-500 inline-flex pointer-events-none items-center transition hover:opacity-80 border-t-3 transparent hover-gray-50 font-medium pt-4 pr-1"
            >
              Poprzednie
            </button>
          </template>
          <template v-else>
            <NuxtLink
              :to="to(page - 1)"
              class="text-sm text-gray-100 inline-flex items-center transition hover:opacity-80 border-t-3 transparent hover-gray-50 font-medium pt-4 pr-1"
              :class="{ 'text-gray-500 pointer-events-none': isFirstPage }"
            >
              Poprzednie
            </NuxtLink>
          </template>
        </div>

        <div class="hidden md:-mt-px md:flex">
          <template v-if="pageCount === 0">
            <button
              disabled
              class="text-sm text-primary inline-flex pointer-events-none items-center transition hover:opacity-80 border-t-3 transparent hover-gray-50 font-medium pt-4 pr-1"
            >
              1
            </button>
          </template>

          <template
            v-for="i in pageCount > 1 ? (pageCount > 2 ? 3 : 2) : 1"
            v-else
            :key="i"
          >
            <NuxtLink
              :to="to(i)"
              :class="[
                i == page
                  ? 'border-primary hover:border-primary-600 text-primary'
                  : 'border-transparent hover:border-gray-50',
              ]"
              class="text-gray-100 transition hover:border-gray-100 border-t-4 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              {{ i }}
            </NuxtLink>
          </template>

          <template v-if="pageCount > 3">
            <span
              class="border-transparent text-gray-500 border-t-4 pt-4 px-4 inline-flex items-center text-sm font-medium"
            >
              ...
            </span>

            <template v-if="page > 3 && page < pageCount - 2">
              <NuxtLink
                to="#"
                class="text-gray-100 transition border-primary hover:border-primary-600 border-t-4 pt-4 px-4 inline-flex items-center text-sm font-medium"
              >
                {{ page }}
              </NuxtLink>

              <span
                class="border-transparent text-gray-500 border-t-2 pt-4 px-4 inline-flex items-center text-sm font-medium"
              >
                ...
              </span>
            </template>

            <template
              v-for="i in 3"
              :key="i"
            >
              <!-- Calculate the last three page numbers (pageCount-2, pageCount-1, pageCount) -->
              <NuxtLink
                :to="to(pageCount - 2 + i)"
                :class="[
                  pageCount - 2 + i === page
                    ? 'border-primary hover:border-primary-600 text-primary'
                    : 'border-transparent hover:border-gray-50',
                ]"
                class="text-gray-100 transition hover:border-gray-100 border-t-4 pt-4 px-4 inline-flex items-center text-sm font-medium"
              >
                {{ pageCount - 2 + i }}
              </NuxtLink>
            </template>
          </template>
        </div>

        <div class="flex flex-1 justify-end">
          <template v-if="isLastPage">
            <button
              disabled
              class="text-sm text-gray-500 inline-flex pointer-events-none items-center transition hover:opacity-80 border-t-4 border-transparent hover-gray-50 font-medium pt-4 pr-1"
            >
              Następne
            </button>
          </template>
          <template v-else>
            <NuxtLink
              :to="to(page + 1)"
              class="text-sm text-gray-100 inline-flex items-center transition hover:opacity-80 border-t-4 border-transparent hover:border-gray-50 font-medium pt-4 pr-1"
              :class="{ 'text-gray-500 pointer-events-none': isLastPage }"
            >
              Następne
            </NuxtLink>
          </template>
        </div>
      </div>
    </UContainer>
  </nav>
</template>
